<template>
  <div class="p-grid crud-demo">
    <div class="p-col-12">
      <div class="card layout-toppanel">
        <p><i class="pi pi-fw pi-info-circle"></i> Reporte</p>
      </div>
    </div>
  </div>

  <div class="card">
    <Toast />
    
    
        <p>
          <i class="pi pi-fw pi-file"></i>
          <a target="_BLANK" href="https://dev2.regionsanmartin.gob.pe/tupa/api/v1/reporte/reporte2?formato=pdf">Registros nuevos</a>
        </p>
        <p>
          <i class="pi pi-fw pi-file"></i>
          <a target="_BLANK"  href="https://dev2.regionsanmartin.gob.pe/tupa/api/v1/reporte/reporte3?formato=pdf">Registros Archivados</a>
        </p>
    
    
  </div>
</template>
<script>
export default {
  data() {
    return {
      reporte: {
        estado: true,
      },
    };
  },
  mounted() {
    let muta = {};

    if (this.$route.query.oper === undefined) {
      muta.oper = "list";
      muta.reporte = this.reporte;
    } else {
      muta.oper = this.$route.query.oper;
    }

    this.$store.commit("gstupa/OPERACION_TUPA", muta);
  },
};
</script>
<style>
</style>